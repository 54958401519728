import { requests } from './requests'
import { isNumber } from '@/func/func.js'
export function getCourse(college) {
    return requests({
        url: '/getcourse.php?areaCode=' + college,
        //url:'/getcourse.php',
        //method:'post',
        //data:{id:1,name:"ddd"}
    })
}
export function getCollege(mode,searchString) {
    return requests({
        url: '/getcollege.php?m=' + mode +'&s=' + searchString,
    })
}
export function getProvince() {
    return requests({
        url: '/getprovince.php',
    })
}

export function requestApplications(searchString) {
    //输入为排名数字，按排名查询可用志愿
    if (isNumber(searchString)) {
        return requests({
            url: '/getapplication.php?position=' + searchString,
        })
    } else{//非数字输入为大学名称，查询该大学当年最低投档线
        return requests({
            url: '/getapplication.php?name=' + searchString,
        })
    }
}
export function requestNewApplications(searchString) {
    //输入为排名数字，按排名查询可用志愿
    if (isNumber(searchString)) {
        return requests({
            url: '/getnewapplication.php?position=' + searchString,
        })
    } else{//非数字输入为大学名称，查询该大学当年最低投档线
        return requests({
            url: '/getnewapplication.php?name=' + searchString,
        })
    }
}
export function requestAdmissionScore(collegeName,majorsGroup) {
    return requests({
        url: '/getadmissionscore.php?c=' + collegeName+'&m=' + majorsGroup,
    })
}
//获取学科评估结果
export function requestXkpg(collegeName) {
    return requests({
        url: '/getxkpg.php?n=' + collegeName,
    })
}