<template>
	<div class="row border text-center">
		<div class="col-md-3 border border-light fw-bolder">{{application.college_name}}</div>
		<div class="col-md-2 border border-light">{{application.major_group}}</div>
		<div class="col-md-3 border border-light">{{application.needed_course}}</div>
		<div class="col-md-2 border border-light">{{application.minimum_score}}</div>
		<div class="col-md-2 border border-light">{{application.position}}</div>
	</div>
</template>
<script>
export default{
	props:['application'],
}
</script>
<style scoped>
.row:hover{
	background-color: #ccc;
}
</style>