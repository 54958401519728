import axios from 'axios'

export function requests(config) {
    const instance = axios.create({
        baseURL: 'https://admission.dkangaroo.cn',
        // baseURL: 'http://vue.wxd.gxt',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        timeout: 5000,
    });
    //添加请求拦截器
    instance.interceptors.request.use(function (config) {
        // 在发送请求之前做些什么
        return config;
    }, function (error) {
        // 对请求错误做些什么
        return Promise.reject(error);
    });

    // 添加响应拦截器
    instance.interceptors.response.use(function (response) {
        // 对响应数据做点什么
        //console.log(response);
        return response;
    }, function (error) {
        // 对响应错误做点什么
        return Promise.reject(error);
    });

    return instance(config);
}