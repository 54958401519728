<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link> | -->
    <router-link to="/require">选课要求</router-link> | 
    <router-link to="/">大学信息</router-link> |
    <router-link to="/application">志愿填报</router-link> |
    <router-link to="/newapplication">新志愿数据</router-link> |
    <router-link to="/boya">博雅预测</router-link> | 
    <router-link to="/xkpg">学科评估</router-link> 
  </nav>
  <router-view></router-view>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.divider{
	height:1px;
	border:1px solid #42b983;
	margin-bottom: 1em;
}

.bg-success{
	background-color: #42b983 !important;
}
</style>
