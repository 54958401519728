<template>
	<!-- <div class="row border" @mouseenter="isOver = true" @mouseleave="isOver = false"> -->
	<div class="college-list row border">
		<div class="col-3 text-start">
			<div>
				{{ index + 1 }}、
				<a :href="college.college_url" v-if="college.college_url" target="_blank">{{ college.college_name }}</a>
				<span v-else>{{ college.college_name }}</span>
				<i class="bi bi-star-fill text-danger" v-if="college.college_americalist === '1'"></i>
				<span class="badge rounded-pill bg-success">{{ college.college_985211 }}</span>
				<span v-if="college.college_doublefirst === '1'" class="badge rounded-pill bg-primary">一流</span>
				<span v-if="college.college_jszhpj === '1'" class="badge rounded-pill bg-danger"> 综评 </span>
				<span v-if="college.college_qjjh === '1'" class="badge rounded-pill bg-danger"> 强基 </span>
			</div>
		</div>
		<div class="col-1">{{ college.college_city }} </div>
		<div class="col-1">{{ college.college_master }}</div>
		<div class="col-1">{{ college.college_level }}</div>
		<div class="col-1">{{ college.college_prop }}</div>
		<div class="col-3 doublefirst">{{ college.college_firstclassmajors }}</div>
		<div class="col-2">{{ college.college_matriculationdate }}</div>
	</div>
</template>
<script>
import {ref} from 'vue';
export default {
	name: 'CollegeInfo',
	props: {
		college: {},
		index: Number,
	},
	setup(){
		const isOver = ref(false);
		return{
			isOver
		}
	}
}
</script>

<style>
.college-list:hover{
	background-color: #ccc;
}
input, select, .grid {
	border: 1px solid #42b983;
	background-color: white;
}

.college-title {
	text-align: left;
	color: purple;
	font-weight: bold;
}
/* 
.active {
	background-color: #e9ecef;
	border-top-color: blueviolet;
	border-bottom-color: blueviolet;
} */

/*双一流大学字体顔色*/
.doublefirst {
	color: rgb(212, 0, 255);
}

/*列入美国清单的大学*/
.border_al {
	background-color: #42b983 !important;
}
</style>