<template>
	<div class="row border shadow rounded-3 mb-2 text-start p-1 college-card ">
		<div class="mt-2 mb-2">
			<span class="collegename me-4">{{index+1}} </span>
			<span class="collegename me-2">{{college.college_code}} 
			{{ college.college_name }}</span>
			<span class="badge rounded-pill bg-info align-top">{{college.major_code}}</span>
			<i class="bi bi-star-fill text-danger align-top" v-if="college.college_americalist === '1'"></i>
			<span class="badge rounded-pill bg-success align-top">{{ college.college_985211 }}</span>
			<span class="badge rounded-pill bg-info align-top" v-if="!!college.is_c211">211</span>
			<span v-if="college.college_doublefirst === '1'" class="badge rounded-pill bg-primary align-top">一流</span>
			<span v-if="college.college_jszhpj === '1'" class="badge rounded-pill bg-danger align-top"> 综评 </span>
			<span v-if="college.college_qjjh === '1'" class="badge rounded-pill bg-danger align-top"> 强基 </span>
		</div>
		<div class="divider"></div>
		<div class="col-md-4">
			<div>所在位置：{{ college.college_city }} </div>
			<div>主管部门：{{ college.college_master }}</div>
			<div>办学性质：{{ college.college_prop }}{{ college.college_level }}</div>
		<div class="doublefirst" v-if="!!college.college_firstclassmajors">一流学科：{{ college.college_firstclassmajors }}
		</div>
		</div>
		<div class="col-md-8">
			<div class="row border text-center bg-success fw-bolder" v-if="hasApplications">
				<div class="col-md-3 border border-light">学校</div>
				<div class="col-md-2 border border-light">专业组</div>
				<div class="col-md-3 border border-light">选课要求</div>
				<div class="col-md-2 border border-light">投档分数</div>
				<div class="col-md-2 border border-light">排名</div>
			</div>
			<ApplicationCard v-for="application in applications" :key="application.id" :application="application">
			</ApplicationCard>
		</div>
	</div>
</template>
<script>
import ApplicationCard from "@/components/ApplicationCard.vue";
import { requestApplications } from '@/network/course.js'
import { toRefs, reactive,computed } from "vue";
export default {
	components: {
		ApplicationCard
	},
	props: {
		college: {},
		index: Number,
	},
	setup(props) {
		// const hasApplications = ref(false);
		const state = reactive({
			applications: []
		});
		const hasApplications = computed(() => {
			return state.applications.length>0;
		})
		requestApplications(props.college.college_name).then(res => {
			// state.scorePosition = res.data.score;
			state.applications = [];
			state.applications = res.data.application;
		}).catch(err => {
			console.log(err);
		});
		return {
			hasApplications,
			...toRefs(state)
		}
	},
}
</script>
<style scoped>
.college-card:hover {
	background-color: #ccc;
}

.collegename {
	font-size: 1.5em;
	color: #42b983;
}
.index-number{
	font-size:1.4em;
	color: #42b983;
}
</style>