<template>
	<div class="container">
		<div class="row">
			<!-- <college-header></college-header> -->
			<nav class="navbar navbar-expand-md navbar-light mb-1">
				<div class="container-fluid">
					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01"
						aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarTogglerDemo01">
						<ul class="navbar-nav me-auto mb-2 mb-lg-0">
							<li class="nav-item me-1">
								<select class="form-select border-success" v-model="curProvince" @change="getColleges('prov',curProvince)">
									<option>all</option>
									<option v-for="province in provinces" :key="province.college_name">{{ province.college_province }}
									</option>
								</select>
							</li>
							<li class="nav-item me-1">
								<button type="button" class="btn btn-outline-success" @click="getColleges('prop','985')">985大学</button>
							</li>
							<li class="nav-item me-1">
								<button type="button" class="btn btn-outline-success" @click="getColleges('prop','211')">211大学</button>
							</li>
							<li class="nav-item me-1">
								<button type="button" class="btn btn-outline-success" @click="getColleges('prop','zhpj')">江苏综评</button>
							</li>
							<li class="nav-item me-1">
								<button type="button" class="btn btn-outline-success" @click="getColleges('prop','doublefirst')">一流大学</button>
							</li>
							<li class="nav-item me-1 align-self-center">
								<input type="checkbox" id="listorcard" v-model="isShowCard" />
								<label for="listorcard">卡片</label>
							</li>
						</ul>
						<form class="d-flex me-2">
							<input v-model="collegeName" @keydown.enter.prevent="getColleges('name',collegeName)" class="rounded-1 border-success me-2" type="search" placeholder="请输入大学名称" aria-label="Search">
							<button type="button" class="btn btn-outline-success" @click.prevent="getColleges('name',collegeName)">查询</button>
						</form>
						<div>
							<input type="checkbox" id="searchBox"  />
							<label for="searchBox">全局查找</label>
						</div>
					</div>
				</div>
			</nav>
		</div>
		<div class="row g-2 " v-if="!isShowCard">
			<CollegeInfo v-for="(college, index) in colleges" :key="college.id" :index="index" :college="college" />
		</div>
		<div class="row g-2" v-else>
			<CollegeCard v-for="(college, index) in colleges" :key="college.id" :index="index" :college="college" />
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted, reactive, toRefs } from 'vue'
import { getCollege, getProvince } from '@/network/course'
import CollegeInfo from '@/components/CollegeInfo'
import CollegeCard from '@/components/CollegeCard.vue'
export default {
	name: 'CollegeView',
	components: {
		CollegeInfo,
		CollegeCard,
	},
	setup() {
		const curProvince = ref('');
		const isShowCard = ref(true);
		const collegeName = ref('');
		const state = reactive({
			provinces: [],
			colleges: [],
		});
		const getColleges = (mode,searchString)=>{
			getCollege(mode,searchString).then(res => {
				state.colleges = res.data.data;
			}).catch(err => {
				console.log(err);
			});
		};
		const getProv = () => {
			getProvince().then(res => {
				state.provinces = res.data;
			}).catch(err => {
				console.log(err);

			})
		};
		onMounted(() => {
			getProv();//取所有的省份
			// getzhpj();
			getColleges("prop","zhpj");//初始显示江苏综合评价大学
		});
		return {
			collegeName,
			isShowCard,
			getColleges,
			curProvince,
			...toRefs(state)
		}

	}
}
</script>