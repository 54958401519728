import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeView.vue'
 import CollegeView from '../views/CollegeView.vue'

const routes = [
  {
    path: '/',
    name: 'college',
    component:CollegeView 
    // redirect:"/college"
  },
  {
    path: '/require',
    name: 'require',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CourseView.vue')
    
  },
  {
    path: '/application',
    name: 'application',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ApplicationView.vue')
  },
  {
    path: '/newapplication',
    name: 'newapplication',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/NewApplicationview.vue')
  },
  {
    path: '/boya',
    name: 'boya',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/BoyaView.vue'),
    props:true
  },
  {
    path: '/xkpg',
    name: 'xkpg',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/XkpgView.vue'),
    props:true
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
